import React from 'react';
import { isWeChatBrowser } from '../common/Utils';
import './AboutSection.css';

export const AboutSection = ({ contactItems }) => {
  const filteredContactItems = isWeChatBrowser()
    ? contactItems.filter((item) => !item.desc.includes('QQ'))
    : contactItems;

  return (
    <section className="about-section">
      <div className='container'>
        <h1 className="about-title">关于我们</h1>
        <p className="about-description">
          前大厂的AI工程师专业团队打造<br />
          <strong>有温度、最专业</strong> 的AI面试辅助
        </p>
        <div className="about-contact-row">
          {filteredContactItems.map((item, index) => (
            <div key={index} className="about-contact-item">
              <img src={item.qrcode} alt={item.desc} className="about-qrcode" />
              <p className="about-contact">{item.desc}</p>
            </div>
          ))}
        </div>
        <p className="about-description">关注我们解锁更多新的福利~</p>

        <footer className="about-footer">
          <p>
            <a href="/terms">服务协议</a> 和 <a href="/privacy">隐私政策</a>
          </p>
          <p>
            Copyright © 2025 OfferJi
          </p>
          {/* <p>广州鸥启科技有限公司&nbsp;&nbsp;广州市白云区永平街集贤路集安街69号521房A354&nbsp;</p> */}
          <p>
            <a
              href="http://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer"
              className="about-footer-beian"
            >
              粤ICP备2024337831号
            </a>
          </p>
        </footer>
      </div>
    </section>
  );
};
